import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';


const Experience = () => {
  const data = {
    languages: [{
      id: 1,
      skill: 'JavaScript',
      level: 'Experienced'
    }, {
      id: 2,
      skill: 'Python',
      level: 'Experienced'
    }, {
      id: 3,
      skill: 'HTML',
      level: 'Experienced'
    }, {
      id: 4,
      skill: 'CSS',
      level: 'Experienced'
    }],
    
    bi_tools: [{
      id: 5,
      skill: 'Adobe Analytics',
      level: 'Experienced'
    }, {
      id: 6,
      skill: 'Looker',
      level: 'Experienced'
    }, {
      id: 7,
      skill: 'Tableau',
      level: 'Intermediate'
    }, {
      id: 8,
      skill: 'Excel / Google Sheets',
      level: 'Experienced'
    }],

    databases: [{
      id: 9,
      skill: 'Google Big Query',
      level: 'Experienced'
    }, {
      id: 10,
      skill: 'AWS Redshift',
      level: 'Intermediate'
    }, {
      id: 11,
      skill: 'MySQL',
      level: 'Intermediate'
    }, {
      id: 12,
      skill: 'MongoDB',
      level: 'Experienced'
    }]
  }

  return (
    <section id='experience'>
      <h5>Skills I Have</h5>
      <h2>Experience</h2>

      <div className='container experience__container'>
        <div>
          <h3>Programming Languages</h3>
          <div className='experience__content'>
          {
            data.languages.map(({id, skill, level,}) => {
              return (
                <article key={id} className='experience__details'>
                  <BsPatchCheckFill className='experience__details_icon' />
                  <div>
                    <h4>{skill}</h4>
                    <small className='text-light'>{level}</small>
                  </div>
                </article>
              )
            })
          }
          </div>
        </div>

        <div>
          <h3>Business Intelligence Tools</h3>
          <div className='experience__content'>
          {
            data.bi_tools.map(({id, skill, level,}) => {
              return (
                <article key={id} className='experience__details'>
                  <BsPatchCheckFill className='experience__details_icon' />
                  <div>
                    <h4>{skill}</h4>
                    <small className='text-light'>{level}</small>
                  </div>
                </article>
              )
            })
          }
          </div>
        </div>

        <div>
          <h3>Databases</h3>
          <div className='experience__content'>
          {
            data.databases.map(({id, skill, level,}) => {
              return (
                <article key={id} className='experience__details'>
                  <BsPatchCheckFill className='experience__details_icon' />
                  <div>
                    <h4>{skill}</h4>
                    <small className='text-light'>{level}</small>
                  </div>
                </article>
              )
            })
          }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience;