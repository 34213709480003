import React from 'react';
import './about.css';
import ME from '../../assets/IMG_2247.png';
import { FaAward, FaUsers } from 'react-icons/fa';
import { VscFolderLibrary } from 'react-icons/vsc';
import ContactButton from '../contact_button/ContactButton';


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me_image'>
            <img src={ME} alt='About' />
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>19+ years</small>
            </article>

            <article className='about__card'>
              <FaUsers className='about__icon'/>
              <h5>Management</h5>
              <small>10+ years</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Web Analytics</h5>
              <small>15+ Years</small>
            </article>
          </div>

          <p>Experienced Engineer/Business Intelligence/Data Analyst with 19+ years in the media industry. Expertise in web analytics, data visualization, and custom data solutions. Proven track record in team management, analysis, testing, and project initiation. Thrives in fast-paced environments, delivering results on time.</p>

          <ContactButton />
        </div>
      </div>
    </section>
  )
}

export default About;