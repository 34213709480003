import React from 'react';
import './portfolio.css';
import IMG1 from '../../assets/simple_dashboard.png';
import IMG2 from '../../assets/finance.png';
import DEFAULT_IMG from '../../assets/coming_soon.png';


const Work = () => {
  const data = [{
    id: 1,
    image: IMG1,
    title: 'Simple Dashboard',
    desc: 'A small dashboard utilizing a Nintendo game release dataset.',
    github: 'https://github.com/peraltaf/simple_dashboard',
    demo: 'https://simpledash.fperalta.me'
  }, {
    id: 2,
    image: IMG2,
    title: 'Finance Dashboard',
    desc: 'A dashboard utilizing a stock picker that displays relevant news, reports, and a candlestick chart.',
    github: 'https://github.com/peraltaf/finance',
    demo: 'https://finance.fperalta.me'
  }, {
    id: 3,
    image: DEFAULT_IMG,
    title: 'TBD',
    desc: 'I\'m gathering ideas on my next project. Stay tuned for more...',
    github: '',
    demo: ''
  }];

  return (
    <section id='portfolio'>
      <h5>Work Samples</h5>
      <h2>Porfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({id, image, title, desc, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item_image'>
                  <img src={image} alt={title} />
                </div>

                <h3>{title}</h3>

                <p className='portfolio__item_desc'>{desc}</p>
                
                <div className='portfolio__item_cta'>
                  <a href={github} target='_blank' rel='noreferrer' className={`btn ${github === '' ? 'disabled' : ''}`}>Github</a>
                  <a href={demo} target='_blank' rel='noreferrer' className={`btn btn btn-primary ${demo === '' ? 'disabled' : ''}`}>Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Work;