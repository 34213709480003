import React from 'react';
import Resume from '../../assets/Felman_Peralta_Resume.pdf';
import { FaFileDownload } from 'react-icons/fa';
import ContactButton from '../contact_button/ContactButton';


const CTA = () => {
  return (
    <div className='cta'>
      <a className='btn' href={Resume} download><FaFileDownload />&nbsp; Resume</a>
      <ContactButton />
    </div>
  )
}

export default CTA;