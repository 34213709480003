import React, { useRef } from 'react';
import './nav.css';
import { AiOutlineHome, AiOutlineUser, AiOutlineFileDone } from 'react-icons/ai';
import { BiBriefcaseAlt, BiMessageSquareDetail } from 'react-icons/bi';


const Nav = () => {
  const ref = useRef(null);

  return (
    <nav ref={ref}>
      <a href='#top' data-to-scrollspy-id='top'><AiOutlineHome /></a>
      <a href='#about' data-to-scrollspy-id='about'><AiOutlineUser /></a>
      <a href='#experience' data-to-scrollspy-id='experience'><BiBriefcaseAlt /></a>
      <a href='#portfolio' data-to-scrollspy-id='portfolio'><AiOutlineFileDone /></a>
      <a href='#contact' data-to-scrollspy-id='contact'><BiMessageSquareDetail /></a>
    </nav>
  )
}

export default Nav;