import React from 'react';
import './footer.css';
import LOGO from '../../assets/logo-no-background.png';
import FULL_LOGO from '../../assets/logo-no-background_full.png';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';


const Footer = () => {
  return (
    <footer id='footer'>
      <div className='footer__logos'>
        <a href='#home'>
          <img src={LOGO} alt='LOGO' className='logo' />
        </a>

        <a href='#home'>
          <img src={FULL_LOGO} alt='FULL_LOGO' className='full_logo' />
        </a>
      </div>
      

      <ul className='permalinks'>
        <li>
          <a href='#home'>Home</a>
        </li>

        <li>
          <a href='#about'>About</a>
        </li>

        <li>
          <a href='#experience'>Experience</a>
        </li>

        <li>
          <a href='#portfolio'>Portfolio</a>
        </li>

        <li>
          <a href='#contact'>Contact</a>
        </li>
      </ul>

      <div className='footer__socials'>
        <a href='https://www.linkedin.com/in/fcperalta/' target='_blank' rel='noreferrer'><BsLinkedin />&nbsp; LinkedIn</a>
        <a href='https://github.com/peraltaf' target='_blank' rel='noreferrer'><FaGithub />&nbsp; Github</a>
      </div>

      <div className='footer__copyright'>
        <small>&copy; All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer;