import React from 'react';
import './contact.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';


const Contact = () => {
  const refForm = useRef();
  const sendEmail = e => {
    e.preventDefault();
    
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_EMAIL_TEMPLATE,
        refForm.current,
        process.env.REACT_APP_EMAILJS
      )
      .then(() => {
        alert('Message successfully sent!');
        window.location.reload(false);
      }, () => {
        alert('Failed to send message, please try again.')
      });
  }

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact me</h2>

      <div className='container contact__container'>
        <div className='contact__map'>
          <MapContainer center={[37.6624, -122.4516]} zoom={14} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            <Marker position={[37.6624, -122.4516]}>
              <Popup>
                Hire me.
              </Popup>
            </Marker>
          </MapContainer>
        </div>

        <form ref={refForm} onSubmit={sendEmail}>
          <ul>
            <input type='text' name='name' placeholder='Name' required />
            <input type='email' name='email' placeholder='E-mail' required />
            <input type='text' name='subject' placeholder='Subject' required />
            <textarea name='message' rows='7' placeholder='Message' required />
            <input type='submit' className='btn btn-primary' value='Send Message' />
          </ul>
        </form>
      </div>
    </section>
  )
}

export default Contact;